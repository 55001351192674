@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto Mono', monospace, -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-feature-settings: "kern" 1,"onum" 1;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.airdrop {
  animation-name: bounce-2;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border: 2px solid rgb(255, 113, 113);
}

@keyframes bounce-2 {
  0%   {
    transform: translateY(0);
    border-color: rgb(255, 113, 113);
  }
  50%  {
    transform: translateY(-25px);
    border-color: rgb(255, 0, 0);
  }
  100% {
    transform: translateY(0);
    border-color: rgb(255, 155, 155);
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.25;
}

.container {
  max-width: 1060px ! important;
}

.logo-icon {
  float: left;
}
.logo-text {
  float: left;
  font-size: 26px;
  font-weight: 600 ! important;
  margin-top: 17px;
  text-transform: uppercase;
}

.border-pinkmoon-bold {
  border: 1px solid #dddddd ! important;
}

.navigation-button {
  float: right;
  margin-top: 18px;
}

.navigation {
  display: inline-block;
  margin: 0 20px 0 0;
}

.navigation ul {
  list-style: none;
}

.navigation ul li {
  display: inline-block;
  margin: 0 20px 0 0;
}

.navigation ul li:last-child {
  margin: 0;
}

.navigation ul li a {
  color: #000000;
  text-decoration: none;
}

.navigation ul li a:hover {
  color: #000000;
  text-decoration: underline;
}

.intro-title {
  font-weight: 600;
}

.custom-main {
  margin: 0;
}

.bg-text p {
  line-height: 1.25;
  word-wrap: break-word;
}

.intro {
  background: #fec5c1;
  color: #000000;
  padding: 120px 0 180px;
  margin: 0 0 120px;
}

.intro h2 {
  font-size: 60px;
  font-weight: 600;
  margin: 0 0 60px;
  text-align: center;
}

.intro h3 {
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 30px;
  text-align: center;
}

.intro-columns {
  margin: 0 -80px 30px;
}

.intro-column {
  float: left;
  width: 25%;
  padding: 0 20px;
  text-align: center;
}

.intro-column h3 {
  font-size: 48px;
  font-weight: 600;
  margin: 0 0 10px;
}

.intro-column p {
  font-size: 21px;
  font-weight: 400;
  margin: 0;
}

.intro-button {
  margin: 45px auto 30px;
  text-align: center;
}

.intro-button a {
  display: inline-block;
  background: #f7a59b;
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  padding: 20px 60px;
  border-radius: 10px;
  text-decoration: underline;
}

.intro-button a:hover {
  text-decoration: none;
}

h2.pools-title {
  font-size: 32px;
  font-weight: 600;
}

.features {
  margin: 0 -20px 90px;
}

.feature {
  float: left;
  width: 33.33333%;
  padding: 0 20px 20px;
}

.feature img {
  max-width: 140px;
  margin: 0 0 30px;
}

.feature h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 30px;
}

.feature p {
  margin: 0 0 25px;
}

.whitelist {
  margin: 0 0 120px;
  text-align: center;
}

.whitelist-button a {
  display: inline-block;
  background: #f7a59b;
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  padding: 20px 60px;
  border-radius: 10px;
  text-decoration: underline;
}

.whitelist-button a:hover {
  text-decoration: none;
}

.roadmap {
  margin: 0 -20px 240px;
}

.roadmap-left {
  float: left;
  width: 50%;
  padding: 0 20px;
}

.roadmap-left h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 60px;
}

.roadmap-right {
  float: right;
  width: 50%;
  padding: 0 20px 0 100px;
}

.roadmap-right h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 60px 0 30px;
}

.roadmap-right p {
  margin: 0 0 10px;
}

.pool-detail-left {
  float: left;
  width: 50%;
  padding-right: 3%;
}

.pool-detail-box {
  float: right;
  width: 50%;
}

.pool-detail-box .p-2 {
  padding: 0;
}

.tier-allocations {
  margin: 0;
}

.tier-allocations h3 {
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 32px;
}

.allocations {
  margin: 0 -16px;
}

.allocation {
  float: left;
  width: 33.3333%;
  padding: 0 16px;
  margin: 0 0 32px;
}

.allocation-box {
  background: #ffffff;
  padding: 32px;
  text-align: center;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 2%) 0px 0px 1px, rgb(0 0 0 / 2%) 0px 4px 8px, rgb(0 0 0 / 2%) 0px 16px 24px, rgb(0 0 0 / 2%) 0px 24px 32px;
}

.allocation h3 {
  font-size: 22px;
  margin: 0 0 16px;
}

.allocation span.guaranteed {
  display: inline-block;
  background: #f7a59b;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 10px;
  margin: 0 0 30px;
  border-radius: 5px;
}

.allocation img {
  width: 100%;
  max-width: 100px;
  margin: 0 auto 30px;
}

.allocation span {
  display: block;
}

.allocation span.holding-avr {
  font-size: 16px;
  font-weight: 600;
  color: #888888;
  margin-bottom: 5px;
}

.allocation span.holding-number {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px;
}

.allocation span.pool-weight {
  font-size: 16px;
  font-weight: 600;
  color: #888888;
  margin-bottom: 5px;
}

.allocation span.pool-number {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.footer {
  background: #f0f0f0;
}

/* responsive */
@media (max-width: 1220px) {

  .intro-columns {
    margin: 0 -20px;
  }

}

@media (max-width: 960px) {

  .navigation-button {
    width: 100%;
    float: left;
    text-align: left;
  }

  .intro-columns {
    margin: 0;
  }

  .intro-column {
    width: 50%;
    padding: 0;
    margin: 0 0 40px;
  }

  .allocation {
    float: none;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto
  }

}


@media (max-width: 767px) {

  .features {
    margin: 0 0 60px;
  }

  .feature {
    float: none;
    width: 100%;
    max-width: 480px;
    padding: 0 0 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .pool-detail-left {
    width: 100%;
    margin: 0 0 30px;
  }

  .pool-detail-box {
    max-width: 100%;
    width: 100%;
  }

}
